import React from 'react';
import './foodmenu.css';
import Footer from '../../footer/Footer';
import lankku from '../../../assets/lankku.png';

const FoodMenu = () => {
  return (
    <div className="food-container">
      <div className="lists-container">
        <div className="list-column">
          <div className="list">
            <h1 className="list-headers">Lounasta</h1>
            <div className="list-content">
              <ul>
                <p className="list-title">
                  <span className="menu-name">Currywursty</span>
                  <span className="line"></span>
                  <span className="list-item-price">€12.50</span>
                </p>

                <p className="list-title">
                  <span className="menu-name">Lasagne</span>
                  <span className="line"></span>
                  <span className="list-item-price">€15.50</span>
                </p>

                <p className="list-title">
                  <span className="menu-name">Kasvislasagne</span>
                  <span className="line"></span>
                  <span className="list-item-price">€15.50</span>
                </p>

                <p className="list-title">
                  <span className="menu-name">Maalaissalaatti</span>
                  <span className="line"></span>
                  <span className="list-item-price">€18.50</span>
                </p>
              </ul>
            </div>

            <h1 className="list-headers">Keitot</h1>
            <div className="list-content">
              <p className="list-title">
                <span className="menu-name">Gulassi</span>
                <span className="line"></span>
                <span className="list-item-price">€13.90</span>
              </p>

              <p className="list-title">
                <span className="menu-name">Kukkakaali</span>
                <span className="line"></span>
                <span className="list-item-price">€10.00</span>
              </p>
            </div>
          </div>
        </div>

        <div className="list-column">
          <div className="list">
            <div className="list-header-container-right">
              <img src={lankku} className="lankkupng" alt="lankku" />
              <h1 className="list-headers">Lankku</h1>
            </div>

            <ul>
              <p className="list-title">
                <span className="menu-name">Havaijin Hurma</span>
                <span className="line"></span>
                <span className="list-item-price">€15.90</span>
              </p>
              <li className="list-item">Kana, aurajuusto, ananas, BBQ-kastike</li>

              <p className="list-title">
                <span className="menu-name">Penan Pizza</span>
                <span className="line"></span>
                <span className="list-item-price">€15.90</span>
              </p>
              <li className="list-item">Kasler, aurajuusto, päärynä</li>

              <p className="list-title">
                <span className="menu-name">Kolme Kaveria</span>
                <span className="line"></span>
                <span className="list-item-price">€15.90</span>
              </p>
              <li className="list-item">Tonnikala, kinkku, salami</li>

              <p className="list-title">
                <span className="menu-name">Pestolina</span>
                <span className="line"></span>
                <span className="list-item-price">€15.90</span>
              </p>
              <li className="list-item">Tomaatti, mozzarella, pesto kastike</li>
            </ul>

            <h1 className="list-headers">Lapsille</h1>
            <div className="list-content">
              <p className="list-title">
                <span className="menu-name">Lihapullat</span>
                <span className="line"></span>
                <span className="list-item-price">€9.00</span>
              </p>

              <p className="list-title">
                <span className="menu-name">Nakit</span>
                <span className="line"></span>
                <span className="list-item-price">€8.00</span>
              </p>

              <p className="list-title">
                <span className="menu-name">Frittibroileri</span>
                <span className="line"></span>
                <span className="list-item-price">€10.00</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FoodMenu;
