import React from 'react';
import './closed.css';
import kermainverted from '../../assets/kermainverted.png';
import 'aos/dist/aos.css';
import img2 from '../../assets/jarvispics/newpic.jpg';

const Closed = () => {
  return (
    <section className="comingsoon" id="comingsoon">
      <div className="background-header">
        <img src={kermainverted} className="logo-closed" alt="kerma-logo" />
        <h4 className="closed-text">
          <span className="closed-kiitos">Kiitos kaudesta.</span>
          <h3 className="sub-header"><span className="heart">♡</span>Kerma  on suljettu tältä talvikaudelta, Kiitos kuluneesta talvesta. Ryhmiä palvelemme sopimuksen mukaan. <span className="heart">♡</span></h3>

          <div className="email-phone">Tahkolahdentie 5, 73310 Tahkovuori <span className="line">| </span> ravintola@tahkonkerma.fi <span className="line">|</span> +358 50 4064012</div>
        </h4>
        
        <img src={img2} className="background-image" alt="kerma-background" />
      </div>
    </section>
  );
};

export default Closed;
